import IGImg from "./IGImg";
import LogoOrTitle from "./LogoOrTitle";

const GameScoreCard = ({ game }, key) => (
  <div key={key} className="flex items-center justify-center gap-4 px-4">
    <LogoOrTitle
      logo={game.VenueLogo}
      title={game.VenueName}
      className="mr-3 w-[80px] text-xl"
    />
    <IGImg
      src={game.HomeTeamLogo}
      alt="Home Team"
      className="max-h-[100px] max-w-[100px] rounded-full"
    />
    <div className="flex h-[110px] w-[110px] items-center justify-center bg-white">
      <p className="w-fit border-b-2 border-black text-7xl font-normal text-black">
        {game.Score}
      </p>
    </div>
    <IGImg
      src={game.AwayTeamLogo}
      alt="Away Team"
      className="max-h-[100px] max-w-[100px] rounded-full"
    />
    <p 
      className="ml-3 mr-8 w-[60px] font-league text-lg text-center" 
      style={{ color: game.GroupColor ?? '#777777' }}
    >
      {game.GroupName}
    </p>
  </div>
);

export default GameScoreCard;
