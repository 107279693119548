import React from 'react'

const EmbedRadioGroup = ({ className, options, value, onChange }) => {
  return (
    <div className={`flex items-center justify-center ${className}`}>
      {options.map((option, idx) => (
        <span
          key={idx}
          className={[
            `px-4 py-[6.5px] border-[1px] border-solid border-[#aaa] cursor-pointer text-[0.85rem] desktop:text-[0.9rem]`,
            idx === 0 ? 'rounded-l-xl' : idx === options.length - 1 ? 'rounded-r-xl' : '',
            idx !== options.length - 1 ? 'border-r-0' : '',
            value == option.value ? 'bg-[#aaa] text-white' : 'text-[#1f1f1f]'
          ].join(' ')}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </span>
      ))}
    </div>
  )
}

export default EmbedRadioGroup


