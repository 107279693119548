import { DownOutlined, MenuOutlined } from '@ant-design/icons';
import { Drawer, Dropdown, Layout } from 'antd';
import ConfirmGlobal from 'components/ConfirmGlobal';
import GoogleAnalyticsTracker from 'components/GoogleAnalyticsTracker';
import LocationHistory from 'components/LocationHistory';
import Navbar from 'components/Navbar';
import Notifications from 'components/Notifications';
import OpenReplayTracker from 'components/OpenReplayTracker';
import RouterScrollToTop from 'components/RouterScrollToTop';
import UserMenu from 'components/UserMenu';
import useOrganization from 'hooks/useOrganization';
import { useSearchParamsMaster } from 'hooks/useSearchParamsMulti';
import { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import useLocalStore from 'store/localStore';
import useLoginStore from 'store/loginStore';
import { prefixRelativeOrg } from 'utils/prefixRelativeOrg';
const { Header, Sider } = Layout;

export default function AdminLayout({ mode, navbar = true, ...props }) {
  const { user } = useLoginStore();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { organization } = useOrganization();
  const organizationName = organization?.name;
  const navigate = useNavigate();
  const setOrganizationId = useLocalStore(state => state.setOrganizationId);
  
  useSearchParamsMaster();

  // Prevent drag & drop of files, except where explicitly allowed by a subcomponent -- except for the Upload component
  useEffect(() => {
    const disableDragAndDrop = (event) => {
      let target = event.target;
      while (target && target !== document) {
        if (target.classList && target.classList.contains('ant-upload')) {
          return;
        }
        target = target.parentNode;
      }
      event.preventDefault();
      event.dataTransfer.effectAllowed = 'none';
      event.dataTransfer.dropEffect = 'none';
    };
    // Adding document level event listeners
    document.addEventListener('dragover', disableDragAndDrop);
    document.addEventListener('drop', disableDragAndDrop);
    // Cleanup document level event listeners
    return () => {
      document.removeEventListener('dragover', disableDragAndDrop);
      document.removeEventListener('drop', disableDragAndDrop);
    };
  }, []);

  const getOrganizationMenuItems = () => {
    return [
      {
        key: 0,
        label: <span className="block text-black font-bold cursor-default">Organizations:</span>,
        disabled: true,
      },
      {
        type: 'divider',
      },
      ...(user.Organizations.sort((a, b) => a?.name?.localeCompare(b?.name)).map(org => ({
        key: org.organizationId,
        label: org.name,
        onClick: () => {
          setOrganizationId(org.organizationId);
          navigate(prefixRelativeOrg(org.urlSlug));
          setMenuIsOpen(false);
        },
      })))
    ];
  }

  const Analytics = () => (
    <>
      <OpenReplayTracker 
        projectKey={process.env.REACT_APP_OPENREPLAY_KEY}
        userId={user?.email} 
        disableSecureMode={process.env.REACT_APP_OPENREPLAY_DISABLE_SECURE_MODE == 'true'} 
        metadata={{ ENVIRONMENT: process.env.REACT_APP_OPENREPLAY_ENVIRONMENT }} />

      <GoogleAnalyticsTracker id={process.env.REACT_APP_ADMIN_GA4_ID} />
    </>
  );

  const MobileHeader = () => (
    <span className="desktop:hidden pl-4 pr-4 flex items-center cursor-pointer" onClick={() => setMenuIsOpen(!menuIsOpen)}>
      <span className="custom-text-a6b4d7"><MenuOutlined /></span>
      <span className="inline-block w-4"></span>
      <span className="custom-text-a6b4d7 text-md overflow-hidden max-w-[calc(100vw-100px)] truncate desktop:max-w-full">{organizationName}</span>
    </span>
  );

  const DesktopOrganizationSwitcher = () => (
    <Dropdown menu={{ items: getOrganizationMenuItems() }} placement="bottomLeft" trigger={['click']}>
      <a className="inline-block select-none" onClick={(e) => e.preventDefault()}>
        <span className="custom-text-a6b4d7 text-md">{organizationName}</span>
        <DownOutlined className="custom-text-a6b4d7 ml-1" />
      </a>
    </Dropdown>
  );

  const DesktopHeader = () => (
    <span className="hidden desktop:flex items-center">
      <span className="text-2xl"><Link className="text-white" to="/">HMS</Link></span>
      <span className="inline-block w-4"></span>
      <span className="border-r border-gray-500 h-8 mx-2"></span>
      <span className="inline-block w-4"></span>
      {user.Organizations.length > 1 &&
        <DesktopOrganizationSwitcher />
      }
      {user.Organizations.length <= 1 &&
        <span className="custom-text-a6b4d7 text-md">{organizationName}</span>
      }
    </span>
  )

  const CommonHeader = () => (
    <Header className="fixed top-0 left-0 right-0 h-15 z-10 custom-bg-002C8C px-0 pr-4 desktop:px-7">
      <div className="flex">
        <span className="max-w-[60vw]">
          <MobileHeader />
          <DesktopHeader />
        </span>
        <span className="flex-grow"></span>
        <span className="max-w-[40vw] truncate">
          <UserMenu />
        </span>
      </div>
    </Header>
  );

  const MobileOrganizationSwitcher = () => (
    <div className="bg-gray-400 py-4 flex justify-center max-w-[100%]">
      <Dropdown menu={{ items: getOrganizationMenuItems() }} placement="bottomLeft" trigger={['click']}>
        <span className="flex">
          <span className="max-w-[200px] text-white text-md overflow-ellipsis truncate">
            {organizationName}
          </span>
          <DownOutlined className="text-white ml-1" />
        </span>
      </Dropdown>
    </div>
  );

  const MobileDrawer = () => (
    <Drawer
      placement="left"
      closable={true}
      open={menuIsOpen}
      onClose={() => setMenuIsOpen(false)}
      headerStyle={{ display: 'none' }}
      width={250}
      bodyStyle={{
        padding: 0,
        overflow: "scroll"
      }
      }
    >
      <Header className="fixed z-10 custom-bg-002C8C px-0 w-[250px]">
        <span className="desktop:hidden pl-4 pr-4 flex items-center cursor-pointer" onClick={() => setMenuIsOpen(false)}>
          <span className="text-white"><MenuOutlined /></span>
          <span className="inline-block w-4"></span>
          <span className="text-xl text-white">HMS</span>
        </span>
      </Header>

      <div className="flex flex-col text-white h-[calc(100vh-190px)] pt-[64px]">  { /* -190px is necessary on a real device - otherwise RulePacks are not visible */}

        {user.Organizations.length > 1 &&
          <MobileOrganizationSwitcher />
        }

        <Navbar mode={mode} setMenuIsOpen={setMenuIsOpen} />
      </div>
    </Drawer>
  );

  return (
    <LocationHistory>
      <Layout className="selector-admin-layout min-h-screen">
        <ConfirmGlobal />

        <Analytics />

        <Notifications />

        <RouterScrollToTop />

        <CommonHeader />

        {/* Page body */}
        <Layout>
          <div className="flex w-full pt-16">
            <Sider className={`hidden ${navbar ? 'desktop:block' : ''} site-layout-background`} width={200}>
              <div className="fixed min-h-[calc(100vh-70px)] max-h-fit w-[200px] bg-white">
                <div className="h-screen flex flex-col justify-between pb-[70px]">
                  <div className="flex flex-grow flex-col overflow-y-auto">
                    <Navbar mode={mode} setMenuIsOpen={setMenuIsOpen} />
                  </div>
                </div>
              </div>
            </Sider>
            <Layout>
              {props.children ? props.children : <Outlet />}
            </Layout>
          </div>
        </Layout>
      </Layout>

      {/* Mobile Drawer */}
      <MobileDrawer />

    </LocationHistory>
  );
}
