import { Form, message, Spin } from 'antd';
import useOrganization from 'hooks/useOrganization';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Organization consistency check:
// - Redirect the user to the correct organization if there is a mismatch between the loaded record and url slug
// - This is a dirty solution and we should send organizationId to the server instead...

const CompatibilityEnitityOrganizationCheck = ({ entity, data, children }) => {
  const navigate = useNavigate();
  const { organizations, organization } = useOrganization();

  const skipOrganizationIdCheck = ['Organization', 'User'].includes(entity.name);

  const selectedOrganizationId = organization?.organizationId;

  const dataOrganizationId = useMemo(() => {
    switch (entity.name) {
      case 'Organization':
        return null;
      case 'Season':
        return data?.Competition?.organizationId;
      case 'Group':
        return data?.Season?.Competition?.organizationId;
      case 'Phase':
        return data?.Group?.Season?.Competition?.organizationId;
      case 'Disciplinary':
        return data?.Competition?.organizationId;
      case 'SeasonRegistration':
        return data?.Season?.Competition?.organizationId;
      default:
        return data?.organizationId;
    }
  }, [data]);
  const isInADifferentOrganization = !skipOrganizationIdCheck && organization && data && dataOrganizationId !== selectedOrganizationId;

  // Testing purposes only
  if (!skipOrganizationIdCheck && data && !dataOrganizationId) {
    alert('Cannot determine organization for this entity.');
  }

  useEffect(() => {
    if (isInADifferentOrganization) {
      const targetOrganization = organizations.find(org => org.organizationId == dataOrganizationId);
      const targetUrl = entity.entityUrl(data, targetOrganization?.urlSlug);
      console.log('Redirecting to:', targetUrl);

      setTimeout(() => {
        navigate(entity.entityUrl(data, targetOrganization?.urlSlug));
      }, 1000);
    }
  }, [isInADifferentOrganization]);

  if (isInADifferentOrganization) {
    console.error(`Entity is in a different organization (${dataOrganizationId}) vs (${selectedOrganizationId})`);

    const targetOrganization = organizations.find(org => org.organizationId == dataOrganizationId);

    return (<div className="h-full w-full flex flex-col gap-4 items-center justify-center">
      <Spin className="mb-4" />
      <div>Redirecting to organization:</div>
      <div className="font-bold">{targetOrganization?.name}</div>
    </div>)
  }

  return children;

}

export default CompatibilityEnitityOrganizationCheck
