import useFetchEntities from 'hooks/useFetchEntities';
import useFetchEntity from 'hooks/useFetchEntity';
import { useEffect, useMemo, useState } from 'react';
import { formatTimeStringToHHMM } from 'utils/IGdateTimeFunctions';
import PhaseSelector from './Filters/PhaseSelector';
import CompetitionLogo from './Parts/CompetitionLogo';
import IGHeader from './Parts/IGHeader';
import IGImg from './Parts/IGImg';
import VenueTodayGameList from './Parts/VenueTodayGameList';
import DebugPrinter from 'components/Debug/DebugPrinter';
import DateSelector from 'components/Infographics/Filters/DateSelector';
import SizeSelector from 'components/Infographics/Filters/SizeSelector';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import useFetchDataWithOrg from 'hooks/useFetchDataWithOrg';
import usePhaseIdTranslator from 'hooks/usePhaseIdTranslator';
import usePersistentStore from 'store/persistentStore';
import { sortDateAndTime } from 'utils/sorters';
import VenueSelector from './Filters/VenueSelector';
import { dateFormatIG } from './IGconfig';
import Draggable from 'react-draggable';
import { Select, Button } from 'antd';
import "@fontsource/audiowide";
import "@fontsource/bebas-neue";
import "@fontsource/covered-by-your-grace";
import "@fontsource/economica";
import "@fontsource/righteous";
import "@fontsource/russo-one";
dayjs.extend(customParseFormat);

const IG1_GamesToday = ({ showData, exportRef }) => {
  const DEFAULT_SIZE = { width: 1000, height: 1600, preset: '2-COL' };

  const membership = usePersistentStore((state) => state.get('ig-membership'));
  const setMembership = usePersistentStore((state) => state.set('ig-membership'));
  const venue = usePersistentStore((state) => state.get('ig-venue'));
  const setVenue = usePersistentStore((state) => state.set('ig-venue'));
  const date = usePersistentStore((state) => state.get('ig-date')) ?? dayjs(new Date()).format(dateFormatIG);
  const setDate = usePersistentStore((state) => state.set('ig-date'));
  const size = usePersistentStore((state) => state.get('ig-gamestoday-size')) ?? DEFAULT_SIZE;
  const setSize = usePersistentStore((state) => state.set('ig-gamestoday-size'));
  const gameDay1Type = usePersistentStore((state) => state.get('ig-gamestoday-gameday-1-type')) ?? 'X3';
  const setGameDay1Type = usePersistentStore((state) => state.set('ig-gamestoday-gameday-1-type'));
  const gameDay2Type = usePersistentStore((state) => state.get('ig-gamestoday-gameday-2-type')) ?? 'X2';
  const setGameDay2Type = usePersistentStore((state) => state.set('ig-gamestoday-gameday-2-type'));
  const gameDay1PositionPercent = usePersistentStore((state) => state.get('ig-gamestoday-gameday-1-position-percent')) ?? { xPercent: 5, yPercent: 40 };
  const setGameDay1PositionPercent = usePersistentStore((state) => state.set('ig-gamestoday-gameday-1-position-percent'));
  const gameDay2PositionPercent = usePersistentStore((state) => state.get('ig-gamestoday-gameday-2-position-percent')) ?? { xPercent: 60, yPercent: 80 };
  const setGameDay2PositionPercent = usePersistentStore((state) => state.set('ig-gamestoday-gameday-2-position-percent'));
  const seasonId = membership?.seasonId;
  const { phaseIdsAll } = usePhaseIdTranslator(membership);

  // GameDay icons drag & drop:

  const GAME_DAY_ICON_SIZES = {
    'NONE': { label: 'None', width: 0 },
    'X1': { label: 'Small', width: size.width * 0.25 },
    'X2': { label: 'Medium', width: size.width * 0.33 },
    'X3': { label: 'Large', width: size.width * 0.5 },
  }

  const gameDay1Size = GAME_DAY_ICON_SIZES[gameDay1Type]?.width ?? 100;
  const gameDay2Size = GAME_DAY_ICON_SIZES[gameDay2Type]?.width ?? 100;

  const gameDay1Position = { x: gameDay1PositionPercent.xPercent * size.width / 100, y: gameDay1PositionPercent.yPercent * size.height / 100 };
  const setGameDay1Position = (position) => {
    setGameDay1PositionPercent({ xPercent: position.x / size.width * 100, yPercent: position.y / size.height * 100 });
  };

  const gameDay2Position = { x: gameDay2PositionPercent.xPercent * size.width / 100, y: gameDay2PositionPercent.yPercent * size.height / 100 };
  const setGameDay2Position = (position) => {
    setGameDay2PositionPercent({ xPercent: position.x / size.width * 100, yPercent: position.y / size.height * 100 });
  };

  const handleGameDay1DragStop = (e, data) => {
    const gameDay1Position = { x: data.x, y: data.y };
    setGameDay1Position(gameDay1Position);
  };

  const handleGameDay2DragStop = (e, data) => {
    const gameDay2Position = { x: data.x, y: data.y };
    setGameDay2Position(gameDay2Position);
  };

  // ---

  const SIZE_PRESETS = [
    {
      label: '1-col (9:16)',
      value: '1-COL',
      aspect: 9 / 16,
      width: 650,
    },
    {
      label: '2-col (9:16)',
      value: '2-COL',
      aspect: 9 / 16,
      width: 915,
    },
    {
      label: '3-col (9:16)',
      value: '3-COL',
      aspect: 9 / 16,
      width: 1340,
    },
    'CUSTOM'
  ];

  const { data: seasonDetail } = useFetchEntity('Season', seasonId, 'Competition');
  const { data: teamStandings } = useFetchDataWithOrg(phaseIdsAll
    ? `/admin/tableStandings?phaseIds=${phaseIdsAll?.join(',')}`
    : null);

  const venueIdsAll = venue?.venueIds == null || venue?.venueIds.length === 0 ? undefined : venue?.venueIds;

  const { data: gamesToday } = useFetchEntities(
    phaseIdsAll != null && date != null && 'Game',
    {
      filters: { startDate: date, phaseId: phaseIdsAll, venueId: venueIdsAll },
      relations: 'Venue(name,logoUrl,igBgImageUrl),HomeTeam(name,logoUrl),AwayTeam(name,logoUrl),Phase>Group(name,color)',
    }
  );

  const metadata = {
    SeasonName: seasonDetail?.name,
    CompetitionName: seasonDetail?.Competition?.name,
    CompetitionLogo:
      seasonDetail?.Competition?.logoUrl?.replace(
        "[size]",
        "cropped_md",
      ),
    Date: date,
  };

  const gamesTodayGrouppedByVenueName = gamesToday?.reduce((acc, game) => {
    if (game.HomeTeam == null && game.AwayTeam == null) {    // don't show empty slots
      return acc;
    }
    const venueName = game.Venue?.name ?? 'Unknown';
    if (!acc[venueName]) {
      acc[venueName] = [];
    }
    acc[venueName].push(game);
    return acc;
  }, {});

  const data = useMemo(() => {
    return Object.values(gamesTodayGrouppedByVenueName ?? {})?.map((games) => {
      return {
        VenueName: games[0].Venue?.name,
        VenueLogo: games[0].Venue?.logoUrl?.replace("[size]", "cropped_md"),
        VenueBgImageUrl: games[0].Venue?.igBgImageUrl,
        Games: games
          .sort(
            (g1, g2) => sortDateAndTime(g1.startDate, g1.startTime, g2.startDate, g2.startTime)
          ).map((game) => {
            const HomeTeamStandings = teamStandings?.find(
              (team) => team["Team Name"] === game.HomeTeam?.name,
            );
            const AwayTeamStandings = teamStandings?.find(
              (team) => team["Team Name"] === game.AwayTeam?.name,
            );
            return {
              Time: formatTimeStringToHHMM(game.startTime),
              HomeTeamName: game.HomeTeam?.name,
              HomeTeamLogo: game.HomeTeam?.logoUrl?.replace(
                "[size]",
                "cropped_md",
              ),
              AwayTeamName: game.AwayTeam?.name,
              AwayTeamLogo: game.AwayTeam?.logoUrl?.replace(
                "[size]",
                "cropped_md",
              ),
              GroupName: game.Phase?.Group?.name,
              GroupColor: game.Phase?.Group?.color,
              HomeTeamWins: HomeTeamStandings?.Wins,
              HomeTeamLosses: HomeTeamStandings?.Losses,
              HomeTeamDraws: HomeTeamStandings?.Draws,
              AwayTeamWins: AwayTeamStandings?.Wins,
              AwayTeamLosses: AwayTeamStandings?.Losses,
              AwayTeamDraws: AwayTeamStandings?.Draws,
              LeagueLogo: null, // phaseDetail?.Group.Season.Competition.logoUrl, //ADAM: is this supposed to be leaguelogo instead of VS string?
            };
          }),
      };
    });
  }, [teamStandings, gamesTodayGrouppedByVenueName]);

  const venueBgImage = useMemo(() => {
    if (venue == null || data == null) {
      return null;
    }
    // find all unique VenueBgImageUrl in data
    const venueBgImageUrls = new Set(data.map((venue) => venue.VenueBgImageUrl));
    const allVenuesHasBgImage = data.every((venue) => venue.VenueBgImageUrl != "" && venue.VenueBgImageUrl != null);
    const allVenuesHasSameBgImage = allVenuesHasBgImage && venueBgImageUrls.size === 1
    if (allVenuesHasSameBgImage) {
      return venueBgImageUrls.values().next().value;
    }
    return null;
  }, [data, venue]);

  return (
    <>
      <div className="grid grid-cols-[auto,1fr] gap-3 items-center">

        <span>Membership:</span>
        <PhaseSelector
          value={membership}
          onChange={setMembership}
          mandatory={['competitionId', 'seasonId']}
        />

        <span>Choose date:</span>
        <div>
          <DateSelector
            value={dayjs(date, dateFormatIG)}
            onChange={(dateString) => { setDate(dateString) }}
          />
        </div>

        <span>Choose venue:</span>
        <div>
          <VenueSelector
            value={venue}
            onChange={(venue) => { setVenue(venue) }}
          />
        </div>

        <span>Game Day Icons:</span>
        <span className="flex gap-2">
        <Select
            className="w-[150px]"
            value={gameDay1Type}
            onChange={(value) => setGameDay1Type(value)}
            options={Object.entries(GAME_DAY_ICON_SIZES).map(([key, value]) => ({ value: key, label: value.label }))}
          />
          <Select
            className="w-[150px]"
            value={gameDay2Type}
            onChange={(value) => setGameDay2Type(value)}
            options={Object.entries(GAME_DAY_ICON_SIZES).map(([key, value]) => ({ value: key, label: value.label }))}
          />
          <Button
            onClick={() => {
              setGameDay1Type(undefined);
              setGameDay2Type(undefined);
              setGameDay1PositionPercent(undefined);
              setGameDay2PositionPercent(undefined);
            }}
          >Reset</Button>
        </span>

        <span>Dimensions:</span>
        <div className="flex gap-2 items-center">
          <SizeSelector
            value={size}
            onChange={setSize}
            presets={SIZE_PRESETS}
            defaultValue={DEFAULT_SIZE}
          />
        </div>

        <div />
      </div>

      {showData && (<DebugPrinter data={[seasonDetail, gamesToday, gamesTodayGrouppedByVenueName, teamStandings, data]}></DebugPrinter>)}

      {date && phaseIdsAll &&

        <article
          ref={exportRef}
          className="ig-root relative mx-auto flex w-fit flex-col items-center justify-between gap-8 overflow-hidden bg-hockey-page-bg py-8 font-header text-white"
          style={{
            width: `${size?.width}px`,
            height: `${size?.height}px`,
          }}
        >
          <div className='px-8'>
            {/* TODO: Replace title string with a variable */}
            <IGHeader
              className="border-b-2 border-white"
              title="Upcoming Hockey Games"
              date={metadata.Date}
            />
            <main className={`w-full flex gap-8 justify-center`}>
              {data.map((venue, index) => (
                <VenueTodayGameList key={index} venue={venue} />
              ))}
            </main>
          </div>

          {/* {!venueBgImage &&  */}
          <div className="absolute top-0 left-0 w-full h-full">
            {gameDay1Type && gameDay1Type != 'NONE' &&
              <Draggable position={gameDay1Position} onStop={handleGameDay1DragStop} >
                <IGImg
                  src="/images/game-day.png"
                  className={`absolute cursor-pointer`}
                  style={{ width: `${gameDay1Size}px` }}
                  draggable={false}
                  alt="Game Day"
                />
              </Draggable>
            }
            {gameDay2Type && gameDay2Type != 'NONE' &&
              <Draggable position={gameDay2Position} onStop={handleGameDay2DragStop} >
                <IGImg
                  src="/images/game-day.png"
                  className={`absolute cursor-pointer`}
                  style={{ width: `${gameDay2Size}px` }}
                  draggable={false}
                  alt="Game Day"
                />
              </Draggable>
            }
            </div>
          {/* } */}
          <div className='flex items-center flex-col w-full'>
            {venueBgImage && <IGImg
              src={venueBgImage}
              //src="https://wallup.net/wp-content/uploads/2015/12/235412-nature-landscape-reflection-lake-fall-forest-mist-sunrise-mountain-trees-Canada-clear_sky-sunlight.jpg"
              alt=""
              className="mt-24 mb-10 aspect-[12/7] w-full object-cover"
            />}
            <CompetitionLogo
              src={metadata.CompetitionLogo}
              title={metadata.CompetitionName}
              season={metadata.SeasonName}
            />
          </div>
        </article>
      }
    </>
  )
}

export default IG1_GamesToday