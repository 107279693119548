import HmsEntityFormController from 'components/Form/HmsEntityFormController';
import HmsEntityFormGridBasic from 'components/Form/HmsEntityFormGridBasic';
import HmsField from 'components/Form/HmsField';
import useEntities from 'hooks/useEntities';
import useFields from 'hooks/useFields';
import useLists from 'hooks/useLists';
import useLoadEntity from 'hooks/useLoadEntity';

const RulePackRecord = ({ entity: entityName }) => {
  const entities = useEntities();
  const entity = entities[entityName];
  const { id, isLoading, isError, isNotFound, data, reload } = useLoadEntity({ entity });
  const allFields = useFields();
  const fields = allFields[entityName];
  const lists = useLists();

  const getOptions = (fieldName) => {
    switch (fieldName) {
      case 'listPenaltyTypeId':
        return lists.ListPenaltyType.map(i => ({ value: i.listPenaltyTypeId, label: i.name }));
      case 'listGoalTypeId':
        return lists.ListGoalType.map(i => ({ value: i.listGoalTypeId, label: i.name }));
    }
  }

  return (
    <>
      <HmsEntityFormController
        {... { entity, id, isLoading, isError, isNotFound, data, reload }}
        form={(
          <HmsEntityFormGridBasic>
            {Object.values(fields)
              .filter(f => !f.primaryKey && f.name !== 'clonedFrom')
              .map((f, idx) => (
                <HmsField 
                  key={idx} 
                  name={f.name} 
                  autoFocus={idx == 0} 
                  options={getOptions(f.name)}
                />
              ))}
          </HmsEntityFormGridBasic>
        )}
      />
    </>
  );
}

export default RulePackRecord
