import React, { useEffect, useMemo, useState } from 'react'
import { removeAccents, sorted, unique } from 'utils/oneLiners';
import { Input, Select, Spin } from 'antd';
import useLang from 'hooks/useLang';
import extractTextFromJSX from 'utils/extractTextFromJsx';
import { debounce } from 'lodash';

const FilterWrapper = ({ label, type, value, onChange, options, allowAll, lang, debounce: debounceParam }) => {
  const LANG = useLang(lang);

  // Note: keeping search value in internal state prevents search field from lagging when parent component contains a lot of data

  const [internalValue, onInternalValueChange] = useState(value);

  useEffect(() => {
    if (value != internalValue) {
      onInternalValueChange(value);
    }
  }, [value]);

  const handleValueChange = (value) => {
    onInternalValueChange(value);

    if (!debounceParam) {
      onChange(value);
    } else {
      onChangeDebounced(value);
    }
  }

  const onChangeDebounced = useMemo(() =>
    debounce(onChange, debounceParam),
  []);

  return (
    <div className="flex">
      <div className="font-roboto-flex rounded-l-xl bg-[#aaa] px-2 py-1 w-[130px] desktop:w-[150px] text-white flex justify-center items-center text-[0.9rem] desktop:text-[1.0rem]">{label}:</div>
      <div className="border-b-[1px] border-[#aaa] w-[170px] desktop:w-[150px]">
        {type == 'select' &&
          <Select
            bordered={false}
            className="w-full"
            value={internalValue ?? '[ALL]'}
            options={[...(allowAll ? [{ value: '[ALL]', label: LANG.ALL }] : []), ...(options ?? [])].map(o =>
              ({ value: o.value, label: <span className="font-roboto-flex cursor-pointer text-center w-full inline-block text-[0.8rem] desktop:text-[0.9rem]">{o.label}</span> })
            )}
            filterOption={(inputValue, option) => {
              const text = extractTextFromJSX(option.label);
              return removeAccents(text).toLowerCase().includes(removeAccents(inputValue).toLowerCase());
            }}
            onChange={(value) => handleValueChange(value != '[ALL]' ? value : null)}
            allowClear={allowAll && internalValue != null}
            showSearch={true}
          />
        }
        {type == 'input' &&
          <Input
            className="border-none rounded-none"
            value={internalValue}
            allowClear
            onChange={(e) => handleValueChange(e.target.value)}
          />
        }
      </div>
    </div>
  )
}

export default FilterWrapper
