import EntityAttachments from 'components/EntityAttachments';
import EntityLogoEditor from 'components/EntityLogoEditor';
import HmsEntityFormController from 'components/Form/HmsEntityFormController';
import HmsEntityFormGridBasic from 'components/Form/HmsEntityFormGridBasic';
import HmsField from 'components/Form/HmsField';
import HmsTabbedTables from 'components/HmsTabbedTables';
import VenueAwards from 'components/Venue/VenueAwards';
import VenueCompetitions from 'components/Venue/VenueCompetitions';
import useEntities from 'hooks/useEntities';
import useLoadEntity from 'hooks/useLoadEntity';

const Venue = () => {
  const entities = useEntities();
  const entity = entities.Venue;
  const { id, isLoading, isError, isNotFound, data, reload, relationsData, reloadRelations } = useLoadEntity({
    entity,
    relations: [
      'Competitions',
      'Awards',
    ].join(','),
  });
  // const { data: competitionsData, reload: reloadCompetitions } = useFetchEntity('Venue', id, 'Competitions');
  // const { data: awardsData, reload: reloadAwards } = useFetchEntity('Venue', id, 'Awards');
  const { relationsData: attachmentsData, reloadRelations: reloadAttachments } = useLoadEntity({ entity,
    relations: [
      'Attachments',
    ].join(',')
  });

  return (
    <>
      <HmsEntityFormController
        {... { entity, id, isLoading, isError, isNotFound, data, reload }}
        form={(
          <div className="flex flex-col desktop:flex-row w-full">
            <div className="flex items-center justify-center ml-4 mr-6 mb-5 desktop:mb-0 ">
              <EntityLogoEditor entity={entities.Venue} id={id} data={data} size={112} />
            </div>
            <div className="flex-auto">
              <HmsEntityFormGridBasic>
                <HmsField name="name" autoFocus />
                <HmsField name="nick" />
                <HmsField name="short" />
                <HmsField name="web" placeholder="www.example.com" />
                <HmsField name="igBgImageUrl" placeholder="http://www.example.com/venueImg" />
                <HmsField name="foundDate" />
                <HmsField name="location" />
                <HmsField name="capacity" addonAfter="visitors" />
                <HmsField name="description" />

              </HmsEntityFormGridBasic>
            </div>
          </div>
        )}
        footer={id != '_new_' && (
          <div className="mt-4">
            <div className="mt-4">
              <HmsTabbedTables
                tables={[
                  {
                    title: "Competitions" + (relationsData?.Competitions ? (' (' + relationsData?.Competitions?.length + ')') : ''),
                    children: <VenueCompetitions data={relationsData?.Competitions} sourceEntityId={data?.venueId} onChange={reloadRelations} />
                  },
                  {
                    title: "Awards" + (relationsData?.Awards ? (' (' + relationsData?.Awards?.length + ')') : ''),
                    children: <VenueAwards data={relationsData?.Awards} sourceEntityId={data?.venueId} onChange={reloadRelations} />
                  },
                  {
                    title: "Attachments" + (attachmentsData?.Attachments ? (' (' + attachmentsData?.Attachments?.length + ')') : ''),
                    children: <EntityAttachments entity={entity.name} id={data?.[entity.primaryKey]} data={attachmentsData} reload={reloadAttachments} />,
                  },
                ]}
              />
            </div>
          </div>
        )}
      />
    </>
  );
}

export default Venue
