import React from "react";
import IGImg from "./IGImg";

const CompetitionLogo = ({ src, title, season, className, logoClassName }) => (
  <div className={`flex w-fit text-gray-300 ${className}`}>
    {src && <IGImg className={`h-[80px] w-[80px] mr-4 ${logoClassName}`} src={src} alt="" />}
    <section>
      <h3 className="w-full text-center text-3xl">{title}</h3>
      <h2 className="w-full text-center text-5xl">{season}</h2>
    </section>
  </div>
);

export default CompetitionLogo;
